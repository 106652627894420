@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.Last_4_digit {
    width: 294px;
    border-radius: 4px;
    border: 1px solid #c6cacc;
    float: right;
    background-color: #f1f1f1;
}
.td_style1 {
    width: 149px;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: .4px;
    color: #787885;
    padding-left: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.disableSCcss.MuiTableRow-hover.Mui-selected{
    background: #929292 !important;
}
.MuiBadge-colorSecondary {
    color: #fff;
    background-color: #009cde !important;
}
ol{
    margin-top: 10px !important;
}
.label {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #454d58;
}
.td_style{
    width: 191px;
    height: 32px;
    margin: 0 2px 0 0;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.44px;
    text-align: left;
    color: #19191d;
  }
.disabledReenrollPlanSelection{
    pointer-events: none;
    opacity: 0.8;
}
.listStyle{
    
    text-indent:-1.5em;
    list-style-type: disc;
    list-style-position:inside;
    padding:5px 0px 0px 20px;
    
}

.EnrollNew2Weight {
    /* min-width: 150px; */
    margin-top: 10px;
    margin-left: 30px;
}
.heightDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -50px
}
.EnrollNew2Height {
    /* min-width: 100px; */
    margin-top: 10px;
}
.EnrollNew2HeightInch {
    width: 100px;
    margin-right: 24px;
    margin-top: 20px;
}
.BMIDiv {
    display: flex;
    flex-direction: row
}
.BMIMemberName {
    text-align : left;
    padding : 40px 10px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: bold;
    /* line-height: 4.5px; */
    margin-top: -19px;
}
.visitDiv {
    display: flex;
    flex-direction: row;
}
.visitContainer {
    display: flex;
    flex-direction: row;
    margin-left: -50px;
}
.selectWidth {
    width: 100% !important
}
.disqualifiedWrp {
    width: 100%;
    max-height: 400px;
    display: flex;
}
.disqfTextWrp {
    width: 40%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    text-align:justify;
    font-family : Roboto, Arial, Helvetica, sans-serif;
}
.disqfTableWrp {
    font-size: 14px;
    line-height: 16px;
    max-height: 400px;
}
.summTable {
    padding-left: 10px;
    padding-right: 25px;
    table-layout: 'fixed';
    padding-bottom: 15px;
    padding-top: 15px;
    min-width: 95%;
    flex-basis: 100%
}

@media screen and (max-width: 600px) {
    .EnrollNew2Weight {
        width: 90px;
        margin-left: 10px;
    }
    .BMIDiv {
        display: flex;
        flex-direction: column
    }
    .BMIMemberName {
        padding : 30px 0px 0px 10px;
        width: 100%;
    }
    .visitDiv {
        display: flex;
        flex-direction: column;
    }
    .visitContainer {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
    }
    .seenField {
        margin-left: -5px !important;
    }
    .selectWidth {
        width: 100% !important
    }
    .disqualifiedWrp {
        flex-direction: column;
    }
    .disqfTextWrp {
        width: 100%;
    }
    .disqfTableWrp {
        max-height: 300px;
    }
    .summTable {
        padding-left: 0px;
        padding-right: 0px;
        min-width: 100%;
    }
    .ModalStyle{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80% !important;
        font-style: Lato;
        height: 300px;
        background-color: "background.paper";
        padding: 0;
        box-shadow: 24;
    }
    .footerFont{
        font-size: 8px;
    }
}
.MuiStepLabel-label.MuiStepLabel-active{
    font-weight: 600 !important;
}
.ModalStyle{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 57%;
    font-style: Lato;
    background-color: "background.paper";
    padding: 0;
    box-shadow: 24;
}
iframe{
    position: inherit !important;
}

.ModalStyleVerification{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 337px;
    font-style: Lato;
    background-color: "background.paper";
    padding: 0;
    box-shadow: 24;
}
